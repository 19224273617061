/** @jsx jsx */
import * as React from 'react'
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { imagePath } from '../../../utils/ImagePath'
import { InViewFadeIn } from './InViewFadeIn'
import { easing } from './easing'
import { useInView } from 'react-intersection-observer'

export const Index: React.FC = () => {
  const { ref: logoRef, inView: logoInView } = useInView({
    threshold: 0,
    initialInView: true,
    rootMargin: '50px 0px',
  })

  return (
    <React.Fragment>
      <script src="https://webfont.fontplus.jp/accessor/script/fontplus.js?wr~4NdEndlE%3D&amp;box=1-xqeEWAYxY%3D&amp;timeout=30&amp;pm=1&amp;aa=1&amp;ab=1"></script>

      <svg className="svg-defs">
        <symbol viewBox="0 0 34 34" id="icon--arrow-in-circle">
          <path
            className="cls-1"
            d="M17,0A17,17,0,0,0,0,17c0,.31,0,.61.05.91h21.6L16.48,14a1,1,0,1,1,1.2-1.57l7.51,5.69a1,1,0,0,1-.6,1.78H.26A17,17,0,1,0,17,0Z"
          />
        </symbol>
        <symbol viewBox="0 0 30 30" id="icon--line">
          <path
            className="cls-1"
            d="M14.94,2C6.9,2,.37,7.3.37,13.82c0,5.84,5.18,10.73,12.18,11.65.48.1,1.12.32,1.29.72a3.09,3.09,0,0,1,0,1.32s-.16,1-.2,1.25-.3,1.44,1.26.78,8.38-4.94,11.44-8.45a10.54,10.54,0,0,0,3.12-7.27C29.5,7.3,23,2,14.94,2ZM9.8,17.3a.28.28,0,0,1-.28.28H5.44a.33.33,0,0,1-.19-.08h0a.26.26,0,0,1-.08-.19V11a.27.27,0,0,1,.28-.28h1a.28.28,0,0,1,.28.28v5H9.51a.29.29,0,0,1,.29.29Zm2.46,0a.29.29,0,0,1-.28.28H11a.28.28,0,0,1-.28-.28V10.94a.28.28,0,0,1,.28-.28h1a.29.29,0,0,1,.28.28Zm7.06,0a.29.29,0,0,1-.28.28H17.88s0,0,0,0a.24.24,0,0,1-.08-.06l-2.91-3.94v3.77a.29.29,0,0,1-.28.28h-1a.28.28,0,0,1-.28-.28V10.94a.28.28,0,0,1,.28-.28h1.15v0h0v0h0l0,0,0,0,2.91,3.93V10.94a.29.29,0,0,1,.28-.28h1a.34.34,0,0,1,.31.28Zm5.62-2.65a.28.28,0,0,1-.28.28H21.88V16h2.78a.29.29,0,0,1,.28.29v1a.29.29,0,0,1-.28.28H20.58a.3.3,0,0,1-.2-.08h0a.3.3,0,0,1-.08-.2V11a.3.3,0,0,1,.08-.2h0a.26.26,0,0,1,.19-.08h4.08a.28.28,0,0,1,.28.28v1a.29.29,0,0,1-.28.28H21.88v1.07h2.78a.29.29,0,0,1,.28.28Z"
          />
        </symbol>
        <symbol viewBox="0 0 20 20" id="icon--facebook">
          <path d="M20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 14.9912 3.65684 19.1283 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93047 3.90625 12.2146 3.90625C13.3084 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1922C11.95 6.5625 11.5625 7.3334 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3432 19.1283 20 14.9912 20 10Z" />
        </symbol>
        <symbol viewBox="0 0 20 20" id="icon--twitter">
          <path d="M20.0002 3.91899C19.2515 4.25087 18.4572 4.46861 17.6439 4.56495C18.5003 4.05276 19.1414 3.24681 19.4479 2.29713C18.6425 2.77537 17.7612 3.11212 16.8421 3.29278C16.2788 2.6938 15.5485 2.27778 14.746 2.09863C13.9435 1.91947 13.1056 1.98543 12.341 2.28797C11.5764 2.5905 10.9203 3.11566 10.4576 3.79542C9.99493 4.47518 9.74703 5.27821 9.74603 6.10049C9.74574 6.41438 9.78108 6.7273 9.85137 7.03322C8.21966 6.95161 6.62334 6.52778 5.16604 5.78923C3.70875 5.05069 2.42304 4.01395 1.3924 2.74631C0.86803 3.64973 0.707477 4.71897 0.943392 5.73656C1.17931 6.75415 1.79397 7.64366 2.66238 8.22419C2.01097 8.20446 1.37378 8.02886 0.804225 7.7121C0.804225 7.72966 0.804225 7.74649 0.804225 7.76404C0.804348 8.71158 1.13222 9.62991 1.73224 10.3633C2.33226 11.0966 3.16747 11.5998 4.09622 11.7876C3.49207 11.9521 2.85844 11.9771 2.24319 11.8608C2.50452 12.6767 3.01454 13.3905 3.70181 13.9021C4.38908 14.4137 5.21918 14.6976 6.07581 14.7138C4.6224 15.8545 2.8274 16.473 0.979798 16.4696C0.652687 16.4698 0.325846 16.4507 0.000976562 16.4125C1.87767 17.6181 4.06175 18.2581 6.29235 18.256C13.8398 18.256 17.9672 12.0034 17.9672 6.58112C17.9672 6.40335 17.9629 6.22631 17.9555 6.05074C18.7584 5.46734 19.4508 4.74546 20.0002 3.91899V3.91899Z" />
        </symbol>
        <symbol viewBox="0 0 24 24" id="user">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          />
          <path d="M8.25 13.5C8.94 13.5 9.5 12.94 9.5 12.25C9.5 11.56 8.94 11 8.25 11C7.56 11 7 11.56 7 12.25C7 12.94 7.56 13.5 8.25 13.5Z" />
          <path d="M15.75 13.5C16.44 13.5 17 12.94 17 12.25C17 11.56 16.44 11 15.75 11C15.06 11 14.5 11.56 14.5 12.25C14.5 12.94 15.06 13.5 15.75 13.5Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.20501 6.95201C8.47607 5.73711 8.42075 4.06612 9.16795 2.94531L10.8321 4.05471C10.5793 4.43391 10.5239 5.26292 10.92 5.92302C11.0983 6.22024 11.3504 6.44696 11.6828 6.56992C11.9157 6.65604 12.228 6.70585 12.638 6.65871C12.9672 6.62086 13.2991 6.79678 13.3901 7.1154L13.6099 7.88462C13.7009 8.20324 13.5168 8.53935 13.1906 8.59771C12.3824 8.7423 11.6419 8.68721 10.989 8.44573C10.1809 8.14681 9.58918 7.59228 9.20501 6.95201Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0778 15.3379C9.80729 15.3379 9.60353 15.5882 9.71172 15.8361C10.0966 16.718 10.9763 17.3343 12 17.3343C13.0237 17.3343 13.9034 16.718 14.2883 15.8361C14.3965 15.5882 14.1927 15.3379 13.9223 15.3379H13.5504C13.375 15.3379 13.2225 15.449 13.1246 15.5945C12.8813 15.9558 12.4684 16.1935 12 16.1935C11.5316 16.1935 11.1188 15.9558 10.8755 15.5945C10.7775 15.449 10.625 15.3379 10.4496 15.3379H10.0778Z"
            stroke="#EB2D23"
            strokeWidth="0.230232"
          />
        </symbol>
        <symbol viewBox="0 0 37 9" id="arrow">
          <path d="M36,8.25H1a1,1,0,0,1,0-2H32.9L26.72,1.81A1,1,0,0,1,26.49.42a1,1,0,0,1,1.4-.23l8.69,6.25A1,1,0,0,1,36,8.25Z" />
        </symbol>
        <symbol viewBox="0 0 8 8" id="blank">
          <path
            className="cls-1"
            d="M6,1.17H1.58V0H8V6.42H6.83V2l-6,6L0,7.17Z"
          />
        </symbol>
      </svg>

      <header className="Header">
        <img
          src={imagePath('service_site_index/common/logo.svg')}
          alt="あずかるこちゃん 病児保育ネット予約サービス"
          width="280px"
          height="60px"
          className={`HeaderLogo${logoInView ? ' -hidden' : ''}`}
        />
        <div className="HeaderNav">
          <div className="HeaderNavItem">
            <LoginLink href="/users/sign_in">
              <svg role="img" aria-hidden="true">
                <use xlinkHref="#user" />
              </svg>
              <span>ログイン</span>
            </LoginLink>
          </div>
          <div className="HeaderNavItem">
            <a className="TextLink" href="/for-facility">
              <span>病児保育室運営者向けサイト</span>
              <svg className="TextLink__arrow" aria-hidden="true">
                <use xlinkHref="#arrow" />
              </svg>
            </a>
          </div>
        </div>
      </header>

      <main>
        <InViewFadeIn as="div" className="Firstview">
          <InViewFadeIn as="div" delay={300} className="Copy Firstview__copy">
            <img
              width="360"
              height="98"
              src={imagePath('service_site_index/home/copy.svg')}
              alt="病児保育を、てのひらに。"
            />
          </InViewFadeIn>
          <InViewFadeIn className="Logo" as="h1">
            <img
              src={imagePath('service_site_index/common/logo.svg')}
              alt="あずかるこちゃん 病児保育ネット予約サービス"
              width="480px"
              height="106px"
              ref={logoRef}
            />
          </InViewFadeIn>
          <div className="Img--01">
            <img
              src={imagePath('service_site_index/home/img--01.jpg')}
              alt=""
              width="1257"
              height="892"
            />
          </div>

          <InViewFadeIn
            as="div"
            delay={400}
            className="BtnWrap BtnWrap--vertical BtnWrap--w-340 Firstview__btns"
          >
            <div className="BtnWrap__item">
              <a
                href="/map_facilities"
                className="RoundedBtn RoundedBtn--c-red RoundedBtn--h-86 RoundedBtn--w-340"
              >
                <div className="RoundedBtnBody">
                  <div className="RoundedBtnBody__line">
                    <span className="fs-14">あずかるこちゃんを</span>
                  </div>
                  <div className="RoundedBtnBody__line">
                    <span className="fs-20">今すぐ無料で使う</span>
                  </div>
                </div>
                <span className="RoundedBtn__icon RoundedBtn__icon--arrow-in-circle">
                  <svg aria-hidden="true">
                    <use xlinkHref="#icon--arrow-in-circle" />
                  </svg>
                </span>
              </a>
            </div>
            <div className="BtnWrap__item">
              <LINELink href="https://lin.ee/BL5woF8" target="_blank">
                <span>LINEの友だち追加からも利用できます</span>
                <svg role="img" aria-hidden="true">
                  <use xlinkHref="#icon--line" />
                </svg>
              </LINELink>
            </div>
          </InViewFadeIn>

          <div className="Firstview__sp-admin">
            <a className="TextLink" href="/for-facility">
              <span>病児保育室運営者向けサイト</span>
              <svg className="TextLink__arrow">
                <use xlinkHref="#arrow" />
              </svg>
            </a>
          </div>
        </InViewFadeIn>

        <Section className="Section--overview">
          <SectionOverView__1>
            <InViewFadeIn as="p" className="Section__title">
              あずかるこちゃんは、
              <br />
              病児保育室の予約をスマホひとつで
              <br />
              24時間いつでも簡単に申し込める
              <br className="show-sp" />
              サービスです。
            </InViewFadeIn>
            <InViewFadeIn as="div" rootMargin="-300px" className="Img--02">
              <div className="Img--02__1"></div>
              <div className="Img--02__2"></div>
              <img
                src={imagePath('service_site_index/home/img--02.jpg')}
                alt=""
              />
            </InViewFadeIn>
            <InViewFadeIn as="ul" className="LargeIconList">
              <li className="LargeIconListItem">
                <img
                  className="LargeIconListItem__figure"
                  src={imagePath('service_site_index/home/largeicon--free.svg')}
                  alt=""
                />
                <p className="LargeIconListItem__label">
                  システム利用は
                  <br />
                  無料
                </p>
              </li>
              <li className="LargeIconListItem">
                <img
                  className="LargeIconListItem__figure"
                  src={imagePath('service_site_index/home/largeicon--line.svg')}
                  alt=""
                />
                <p className="LargeIconListItem__label">
                  LINEからも
                  <br />
                  予約＆キャンセル
                </p>
              </li>
              <li className="LargeIconListItem">
                <img
                  className="LargeIconListItem__figure"
                  src={imagePath('service_site_index/home/largeicon--dx.svg')}
                  alt=""
                />
                <p className="LargeIconListItem__label">
                  面倒な手続きを
                  <br />
                  オンライン化
                </p>
              </li>
            </InViewFadeIn>
          </SectionOverView__1>
          <SectionOverView__Wrapper>
            <SectionOverView__2>
              <InViewFadeIn
                as="p"
                className="Section__text"
                rootMargin="-200px"
              >
                お近くの病児保育室をマップや
                <br />
                名称から手軽に検索。
                <br />
                利用の登録・予約もスムーズに。
              </InViewFadeIn>
              <InViewFadeIn
                as="div"
                className="OverviewImg OverviewImg--01"
                rootMargin="-200px"
              >
                <span className="Shape Shape--overview-01-1"></span>
                <span className="Shape Shape--overview-01-2"></span>
                <img
                  width="512"
                  height="537"
                  src={imagePath('service_site_index/home/overview--01.svg')}
                  alt=""
                />
              </InViewFadeIn>
            </SectionOverView__2>
            <SectionOverView__3>
              <InViewFadeIn
                as="p"
                className="Section__text"
                rootMargin="-200px"
              >
                予約などの操作はLINEからも可能。
                <br />
                病児保育室からの
                <br />
                リマインド機能も便利です。
              </InViewFadeIn>
              <InViewFadeIn
                as="div"
                className="OverviewImg OverviewImg--02"
                rootMargin="-200px"
              >
                <span className="Shape Shape--overview-02-1"></span>
                <span className="Shape Shape--overview-02-2"></span>
                <img
                  src={imagePath('service_site_index/home/overview--02.svg')}
                  alt=""
                />
              </InViewFadeIn>
            </SectionOverView__3>
            <SectionOverView__4>
              <InViewFadeIn
                as="p"
                className="Section__text"
                rootMargin="-200px"
              >
                手書きして提出しなければ
                <br />
                いけなかった用紙も
                <br />
                スマホから記入・提出できます。
              </InViewFadeIn>
              <InViewFadeIn
                as="div"
                className="OverviewImg OverviewImg--03"
                rootMargin="-200px"
              >
                <span className="Shape Shape--overview-03-1"></span>
                <span className="Shape Shape--overview-03-2"></span>
                <img
                  src={imagePath('service_site_index/home/overview--03.svg')}
                  alt=""
                />
              </InViewFadeIn>
            </SectionOverView__4>
          </SectionOverView__Wrapper>
        </Section>

        <section className="Section Section--other">
          <InViewFadeIn as="h2" className="Section__title" rootMargin="-200px">
            ほかにも、
            <br className="show-pc" />
            安心で便利な
            <br className="show-sp" />
            機能を増やしています。
          </InViewFadeIn>
          <div className="Grid Grid--other">
            <InViewFadeIn
              as="div"
              className="Grid__col Card"
              rootMargin="-150px"
              threshold={50}
            >
              <img
                src={imagePath('service_site_index/home/other--01.svg')}
                className="Card__figure"
                alt=""
              />
              <p className="Card__title">子どもの過ごす環境を事前に把握</p>
              <p className="Card__text">
                病児保育室の写真やスタッフ紹介など、
                <br />
                情報ページから詳しく確認できます。
              </p>
            </InViewFadeIn>
            <InViewFadeIn
              as="div"
              className="Grid__col Card"
              rootMargin="-150px"
              threshold={100}
            >
              <img
                src={imagePath('service_site_index/home/other--02.svg')}
                className="Card__figure"
                alt=""
              />
              <p className="Card__title">細かいやりとりもスマホで簡単</p>
              <p className="Card__text">
                子どもの様子の共有やお迎え時間変更の相談など、
                <br />
                細かい連絡もスマホでリアルタイムに行えます。
              </p>
            </InViewFadeIn>
          </div>
        </section>

        <section className="Section Section--how">
          <InViewFadeIn as="h2" className="Section__title">
            あずかるこちゃんの使い方
          </InViewFadeIn>
          <InViewFadeIn as="p" className="Section__intro">
            あずかるこちゃんを使っての予約はアカウント作成が必要です。
            <br />
            アカウント作成から予約まで、システムのご利用にお金は一切かかりません。
          </InViewFadeIn>

          <section className="ChildSection">
            <InViewFadeIn as="h3" className="ChildSection__title">
              <span>病児・病後児保育室を利用する前</span>
            </InViewFadeIn>

            <div className="Grid Grid--how-01">
              <div className="Grid__col Card">
                <InViewFadeIn as="div" className="Label">
                  <span className="NumIcon">1</span>
                  <p>あずかるこちゃんのアカウントを作成</p>
                </InViewFadeIn>
                <InViewFadeIn
                  as="img"
                  src={imagePath('service_site_index/home/how--01-01.png')}
                  alt="あずかるこちゃんのアカウント作成画面のスクリーンショット。5つのステップでアカウント作成が完了し、画面には3ステップ目の利用者情報入力が表示されています。"
                  rootMargin="-300px"
                />
              </div>
              <div className="Grid__col Card">
                <div className="Arrow">
                  <svg className="Arrow__img" aria-hidden="true">
                    <use xlinkHref="#arrow" />
                  </svg>
                </div>
                <InViewFadeIn as="div" className="Label">
                  <span className="NumIcon">2</span>
                  <p>利用する施設を検索して利用登録</p>
                  <img
                    className="Label__attention"
                    src={imagePath('service_site_index/home/attention--01.png')}
                    alt="これだけ！"
                  />
                </InViewFadeIn>
                <InViewFadeIn
                  as="img"
                  src={imagePath('service_site_index/home/how--01-02.png')}
                  alt="あずかるこちゃんの施設検索画面のスクリーンショット。マップ上に施設の所在地と、今日または明日の混み具合などが表示されています。"
                  rootMargin="-300px"
                />
              </div>
            </div>
          </section>

          <section className="ChildSection">
            <InViewFadeIn as="h3" className="ChildSection__title">
              <span>病児・病後児保育室を利用したいとき</span>
            </InViewFadeIn>

            <ol className="Grid Grid--how-02">
              <li className="Grid__col Card">
                <InViewFadeIn as="div" className="Label Label--multiline">
                  <span className="NumIcon">1</span>
                  <p>
                    あずかるこちゃんから
                    <br />
                    施設を予約
                  </p>
                </InViewFadeIn>
                <InViewFadeIn
                  as="img"
                  src={imagePath('service_site_index/home/how--02-01.svg')}
                  alt="あずかるこちゃんから施設を予約"
                  rootMargin="-300px"
                />
              </li>
              <li className="Grid__col Card">
                <div className="Arrow">
                  <svg className="Arrow__img" aria-hidden="true">
                    <use xlinkHref="#arrow" />
                  </svg>
                </div>
                <InViewFadeIn as="div" className="Label Label--multiline">
                  <span className="NumIcon">2</span>
                  <p>
                    医療機関を受診して
                    <br />
                    用紙に記入
                  </p>
                </InViewFadeIn>
                <InViewFadeIn
                  as="img"
                  src={imagePath('service_site_index/home/how--02-02.svg')}
                  alt="医療機関を受診して用紙に記入"
                  rootMargin="-300px"
                />
              </li>
              <li className="Grid__col Card">
                <div className="Arrow">
                  <svg className="Arrow__img" aria-hidden="true">
                    <use xlinkHref="#arrow" />
                  </svg>
                </div>
                <InViewFadeIn as="div" className="Label Label--multiline">
                  <span className="NumIcon">3</span>
                  <p>施設に入室</p>
                </InViewFadeIn>
                <InViewFadeIn
                  as="img"
                  src={imagePath('service_site_index/home/how--02-03.svg')}
                  alt="施設に入室"
                  rootMargin="-300px"
                />
              </li>
            </ol>
          </section>
        </section>

        <section className="Section Section--what">
          <InViewFadeIn as="h2" className="Section__title">
            知ってますか？ 病児保育。
          </InViewFadeIn>
          <InViewFadeIn as="p" className="Section__intro">
            病児とは、風邪などの「入院するほど重篤ではないものの、保育園・学校などを休まなければならない子ども」のこと。病児保育は、そんな病児・病後児が安全に1
            日を過ごし、保護者のみなさんも安心してご利用いただけるサービスです。
          </InViewFadeIn>
          <InViewFadeIn as="p" className="Section__annotation">
            ※病気が回復期にある子どものことを病後児といいます。
          </InViewFadeIn>

          <div className="Grid Grid--what">
            <InViewFadeIn
              as="div"
              className="Grid__col Card"
              rootMargin="-150px"
              threshold={100}
            >
              <p className="Card__title">どんなときに使える？</p>
              <img
                src={imagePath('service_site_index/home/what--01.svg')}
                className="Card__figure"
                alt=""
              />
              <p className="Card__text">
                子どもが急病のときや、保護者が仕事を休めず看病できないときなどにご利用いただけます。
              </p>
            </InViewFadeIn>
            <InViewFadeIn
              as="div"
              className="Grid__col Card"
              rootMargin="-150px"
              threshold={100}
            >
              <p className="Card__title">だれがいるの？</p>
              <img
                src={imagePath('service_site_index/home/what--02.svg')}
                className="Card__figure"
                alt=""
              />
              <p className="Card__text">
                病児保育の専門家である保育士や看護師が常駐し、病気の子どもの体調をしっかりみながら保育します。
              </p>
            </InViewFadeIn>
            <InViewFadeIn
              as="div"
              className="Grid__col Card"
              rootMargin="-150px"
              threshold={100}
            >
              <p className="Card__title">病気はうつらない？</p>
              <img
                src={imagePath('service_site_index/home/what--03.svg')}
                className="Card__figure"
                alt=""
              />
              <p className="Card__text">
                インフルエンザなどの感染症の場合、他の子どもに感染しないよう、部屋を分けて保育を行います。
              </p>
            </InViewFadeIn>
          </div>
        </section>
      </main>

      <div className="Conversion">
        <InViewFadeIn as="div" className="BtnWrap BtnWrap--vertical">
          <div className="BtnWrap__item">
            <a
              href="/map_facilities"
              className="RoundedBtn RoundedBtn--c-red RoundedBtn--h-86 RoundedBtn--w-498"
            >
              <div className="RoundedBtnBody">
                <div className="RoundedBtnBody__line RoundedBtnBody__line--sp-multiline">
                  <span className="fs-20 sp-fs-12">
                    あずかるこちゃん<span className="fs-14">を</span>
                  </span>
                  <span className="fs-20">今すぐ無料で使う</span>
                </div>
                <span className="RoundedBtn__icon RoundedBtn__icon--arrow-in-circle">
                  <svg aria-hidden="true">
                    <use xlinkHref="#icon--arrow-in-circle" />
                  </svg>
                </span>
              </div>
            </a>
          </div>
          <div className="BtnWrap__item">
            <LINELink href="https://lin.ee/BL5woF8" target="_blank">
              <span>LINEの友だち追加からも利用できます</span>
              <svg role="img" aria-hidden="true">
                <use xlinkHref="#icon--line" />
              </svg>
            </LINELink>
          </div>
        </InViewFadeIn>
      </div>

      <footer className="Footer">
        <div className="FooterNav">
          <ul className="FooterNavList FooterNavList--primary">
            <li className="FooterNavListItem">
              <a href="/" className="Logo Logo--footer">
                <img
                  src={imagePath('service_site_index/common/logo--type.svg')}
                  alt="あずかるこちゃん 病児保育ネット予約サービス"
                />
              </a>
            </li>
            <li className="FooterNavListItem">
              <a className="FooterNavListItemBtn" href="/terms">
                利用規約
              </a>
            </li>
            <li className="FooterNavListItem">
              <a className="FooterNavListItemBtn" href="/policy">
                プライバシーポリシー
              </a>
            </li>
            <li className="FooterNavListItem">
              <a
                className="FooterNavListItemBtn"
                href="https://docs.google.com/forms/d/e/1FAIpQLSd4L0WHr4KmmEC1mGADaV3EF0l9kPI6Dwai09my-Zx5RIxvmw/viewform"
              >
                お問い合わせ
                <svg
                  className="icon--blank"
                  role="img"
                  aria-label="お問い合わせ"
                >
                  <use xlinkHref="#blank" />
                </svg>
              </a>
            </li>
            <li className="FooterNavListItem">
              <a
                className="FooterNavListItemBtn"
                href="https://goodbaton.jp/"
                target="_blank"
              >
                運営会社
                <svg className="icon--blank" role="img" aria-label="運営会社">
                  <use xlinkHref="#blank" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div className="FooterNav">
          <ul className="FooterNavList FooterNavList--secondary">
            <li className="FooterNavListItem">
              <a
                className="FooterNavListItemBtn FooterNavListItemBtn--twitter"
                href="https://twitter.com/azukaruko"
                target="_blank"
              >
                <svg className="icon--twitter" role="img" aria-label="Twitter">
                  <use xlinkHref="#icon--twitter" />
                </svg>
                @azukaruko
              </a>
            </li>
            <li className="FooterNavListItem">
              <a
                className="FooterNavListItemBtn FooterNavListItemBtn--facebook"
                href="https://www.facebook.com/azukaruko/"
                target="_blank"
              >
                <svg
                  className="icon--facebook"
                  role="img"
                  aria-label="Facebook"
                >
                  <use xlinkHref="#icon--facebook" />
                </svg>
                facebook.com/azukaruko
              </a>
            </li>
            <li className="FooterNavListItem">
              <a
                className="FooterNavListItemBtn"
                href="https://goodbaton.jp/"
                target="_blank"
              >
                &copy; Goodbaton Inc.
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </React.Fragment>
  )
}

const LoginLink = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9px 16px 9px 15px;
  border-radius: 1000px;

  color: #e01a27;
  border: 1px solid #e01a27;
  background-color: #fff;
  font-size: clamp(15px, 1.5vw, 15px);
  font-weight: 700;
  letter-spacing: -0.0517em;

  transition: color 0.4s ${easing.easeOutSine},
    background-color 0.4s ${easing.easeOutSine},
    box-shadow 0.4s ${easing.easeOutSine};

  & > svg {
    fill: currentColor;
    width: 24px;
    height: 24px;
    transition: fill 0.4s ${easing.easeOutSine};
  }

  &:hover,
  &:focus {
    color: white;
    background-color: #e01a27;
    box-shadow: 0px 1px 4px 0px #00000066;

    & > svg {
      fill: white;
    }
  }

  @media screen and (max-width: 750px) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
`

const LINELink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75em;
  padding: 0.85em 1.45em 0.85em 2.1em;
  border-radius: 1000px;

  background-color: #00b900;
  color: white;
  font-size: 0.9375vw;
  box-shadow: 0px 4px 12px 0px #0000002e;

  transition: box-shadow 0.8s ${easing.easeOutExpo};

  & > span {
    white-space: nowrap;
  }

  & > svg {
    fill: currentColor;
    width: 2.5em;
    height: 2.5em;
  }

  @media (max-width: 750px) {
    font-size: 3.2vw;
  }
`

const Section = styled.section`
  position: relative;
`

const SectionOverView__1 = styled.div`
  display: grid;
  padding: 0 20px;
  gap: 25px;

  @media (min-width: 751px) {
    padding: 0 4.86vw 0 8.2vw;
    grid-template-areas:
      'a b'
      'a c';
    gap: 10px 5vw;

    .Section__title {
      grid-area: b;
      padding-top: 48px;
      padding-left: 0;
    }

    .Img--02 {
      grid-area: a;
      justify-content: flex-end;
    }

    .LargeIconList {
      grid-area: c;
    }
  }
`

const SectionOverView__Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 66px;
  font-weight: bold;
  padding: 0 86px;

  @media (max-width: 750px) {
    padding: 4px 24px 0;

    &::before {
      content: '';
      height: 1px;
      width: 100%;
      background-color: rgb(178, 172, 159);
    }
  }
`

const SectionOverView__2 = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: calc(75 / 1440 * 100vw);

  @media (max-width: 750px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 44px;
  }

  .Section__text {
    margin-top: 60px;

    @media (max-width: 750px) {
      margin-top: 40px;
    }
  }
`

const SectionOverView__3 = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: top;
  margin-top: 10px;
  padding-left: 40px;
  column-gap: calc(146 / 1440 * 100vw);

  @media (max-width: 750px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 108px;
    padding-left: 0;
    gap: 13px;
  }

  .Section__text {
    margin-top: 145px;
    z-index: 2;

    @media (max-width: 750px) {
      margin-top: 0;
    }
  }
`

const SectionOverView__4 = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 84px;
  padding-right: calc(33 / 1440 * 100vw);
  gap: calc(119 / 1440 * 100vw);

  @media (max-width: 750px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 120px;
    padding-right: 0;
    gap: 13px;
  }

  .Section__text {
    z-index: 2;
  }
`
